/* eslint-disable simple-import-sort/imports */
import { ShopCode }     from '@/types/globals';

import "@/global/lib/font-awesome/font-awesome.min.css";
import "dropzone/dist/dropzone.css";
import "@/global/css/responsive-style.css";
import "@/global/css/fv-product.css";
import "@/global/css/fv-shopinterface.css";

switch (window.shopShortcode) {
    case ShopCode.FVDE:
        import('./styles/style-fvde.module.css');
        break;
    case ShopCode.FVAT:
        import('./styles/style-fvat.module.css');
        break;
    case ShopCode.FVCH:
        import('./styles/style-fvch.module.css');
        break;
    case ShopCode.FVFR:
        import('./styles/style-fvfr.module.css');
        break;
    case ShopCode.FVBE:
        import('./styles/style-fvbe.module.css');
        break;
    case ShopCode.FVIN:
        import('./styles/style-fvin.module.css');
        break;
    case ShopCode.FVIT:
        import('./styles/style-fvit.module.css');
        break;
    case ShopCode.FVES:
        import('./styles/style-fves.module.css');
        break;
}

import "@/global/css/zend.css";
import "@/global/css/darkThemeVideoLightbox.css";
import "webui-popover/dist/jquery.webui-popover.css";
import "nprogress/nprogress.css";
import "@/global/css/product-promotion.css";
import "@/global/lib/CircularProgressButton/css/component.css";
import "@/global/css/cart/circular-progress-button.scss";
import "@/global/css/redesign/scss/common.scss";
import "@/global/css/cart/payment-payone.scss";
import "@/modules/alert/styles.scss";

const shopsWithKlarna = [
    ShopCode.FVDE,
    ShopCode.FVAT,
    ShopCode.FVCH,
    ShopCode.FVFR,
    ShopCode.FVBE,
];
if (shopsWithKlarna.includes(window.shopShortcode)) {
    import('./styles/klarna.module.scss');
}

const frenchShops = [
    ShopCode.FVFR,
    ShopCode.FVBE,
];
if (frenchShops.includes(window.shopShortcode)) {
    import('./styles/common-french.module.scss');
}

import '@/modules/synonymbox/styles.scss';
import '@/lib/product-detail/styles.scss';
import '@/global/js/defer';
import '@/modules/page-rating';
import '@/lib/responsive-main-nav-bar';
import '@/lib/responsive-footer';
import '@/lib/redesign-profile-list';
import '@/lib/sticky-anchor';
import '@/lib/special-windows-forms';
import '@/lib/swiper/content-slider';
import '@/lib/swiper/review-slider';

import Promobar                from '@/modules/promobar';
import TopCategories           from '@/modules/top-categories';
import { guiToggle }           from '@/utils/dom';
import { executeOnWindowLoad } from '@/utils/withWindowLoad';

executeOnWindowLoad(() => {
    if (/Android 9/.test(window.navigator.userAgent) && /Chrome/.test(window.navigator.userAgent)) {
        document.querySelectorAll('.product-image').forEach(image => {
            image.classList.add('no-blend-mode');
        });
    }

    const promobar = document.getElementById('promobar');
    if (promobar) {
        new Promobar(promobar);
    }

    const categoryTeasers = Array.from(document.querySelectorAll<HTMLElement>('.top-category-container .category-teaser[data-rel]'));
    if (categoryTeasers.length > 0) {
        new TopCategories(categoryTeasers);
    }

    const toggles = document.querySelectorAll('[data-toggle]');
    guiToggle(toggles);
});
