import setGoogleSearch                                                  from '@/modules/google-search';
import { hdeApp }                                                       from '@/modules/hde-app';
import { ConsentManagerVendors, getConsentByVendorId }                  from '@/utils/consent-manager';
import { isMobileHeader, lockBody, setHeaderWrapperHeight, unlockBody } from '@/utils/dom';
import { executeOnWindowLoad }                                          from '@/utils/withWindowLoad';

interface MainNavBarOptions {
    mbHeaderSelector: string,

    mbHeaderMobileClass: string,

    mbHeaderMobileOpenClass: string,

    mbHeaderMobileSubnavOpenClass: string,

    mbMainmenuSelector: string,

    mbMainmenuSubnav1ActiveClass: string,

    mbMainmenuSubnav1CurrentClass: string,

    mbSubnavItemSelector: string,

    mbSubnavItemActiveClass: string,

    mbSubnav1ItemSelector: string,

    mbMobilemenuToggleSelector: string,

    mbMobilemenuToggleBackSelector: string,

    mbSubnavItemToggleFwdSelector: string,

    mbSubnavItemToggleParentSelector: string,

    mbHeaderHistorySubnav1Key: string,

    mbDesktopDropdownToggleSelector: string,

    mbDesktopForwardToggleSelector: string,
}


class ResponsiveMainNavBar {
    private options: MainNavBarOptions = {
        mbHeaderSelector:              '#header',
        mbHeaderMobileClass:           'header-mobile',
        mbHeaderMobileOpenClass:       'header-mobile-open',
        mbHeaderMobileSubnavOpenClass: 'subnav-open',

        mbMainmenuSelector:             '#mainmenu',
        mbMainmenuSubnav1ActiveClass:   'subnav1-active',
        mbMainmenuSubnav1CurrentClass:  'subnav-current',

        mbSubnavItemSelector:    '.subnav-item',
        mbSubnavItemActiveClass: 'open',

        mbSubnav1ItemSelector: '[data-rel="subnav1"]',

        mbMobilemenuToggleSelector:       '#mobilemenu_toggle',
        mbMobilemenuToggleBackSelector:   '.back-button',
        mbSubnavItemToggleFwdSelector:    '[data-rel="fwd"]',
        mbSubnavItemToggleParentSelector: '.dropdown-toggle-mobile',

        mbHeaderHistorySubnav1Key:       'subnav1',
        mbDesktopDropdownToggleSelector: '#header:not(.header-mobile) [data-rel="subnav1"]',
        mbDesktopForwardToggleSelector:  '#header:not(.header-mobile) [data-rel="subnav1"] .dropdown-toggle',
    };

    private element: HTMLElement;

    private isMobileHeaderLast: boolean;

    private headerHistorySubnav1Id = this.options.mbHeaderHistorySubnav1Key;

    private headerHistory: Record<string, string | null>;

    private header: HTMLElement;

    private mainmenu: HTMLElement | null = null;

    private mobilemenuToggle: HTMLElement | null = null;

    private mobilemenuToggleBack: HTMLElement | null = null;

    private subnavItems: HTMLElement[] = [];

    private subnav1Items: HTMLElement[] = [];

    private subnavItemsToggleFwd: HTMLElement[] = [];

    private readonly openLastSubnav1Event = 'openLastSubnav1Event';


    constructor(element: HTMLElement, options?: MainNavBarOptions) {
        this.options = { ...this.options, ...options };
        this.element = element;
        this.isMobileHeaderLast = isMobileHeader();
        this.header = document.querySelector<HTMLElement>(this.options.mbHeaderSelector) as HTMLElement;
        this.headerHistory = {};
        this.headerHistory[this.options.mbHeaderHistorySubnav1Key] = null;
        this.init();
    }


    private getHistorySubnav1Id() {
        return this.headerHistory[this.options.mbHeaderHistorySubnav1Key];
    }

    private updateHistorySubnav1Id(value: string): void {
        this.updateHistoryValue(this.headerHistorySubnav1Id, value);
    }

    private updateHistoryValue(key: string, value: string) {
        this.headerHistory[key] = value;
    }

    private init() {
        this.initElements();
        this.setCurrentSubnav1Item();
        this.initEvents();
        this.checkHeaderMobileAction();
    }

    private initElements() {
        this.mainmenu = this.element.querySelector(this.options.mbMainmenuSelector);
        this.mobilemenuToggle = document.querySelector(this.options.mbMobilemenuToggleSelector);
        this.mobilemenuToggleBack = document.querySelector(this.options.mbMobilemenuToggleBackSelector);

        this.subnavItems = Array.from(this.mainmenu!.querySelectorAll<HTMLElement>(this.options.mbSubnavItemSelector));
        this.subnav1Items = this.subnavItems.filter(el => el.matches(this.options.mbSubnav1ItemSelector));
        this.subnavItemsToggleFwd = this.subnavItems.flatMap(element => Array.from(element.querySelectorAll(this.options.mbSubnavItemToggleFwdSelector)));
    }

    private setCurrentSubnav1Item() {
        if (window.pageRootLine !== undefined) {
            this.subnav1Items.forEach(item => {
                const currentItemRelatedPageId = item.dataset.relPageId;
                if (
                    typeof currentItemRelatedPageId !== 'undefined' &&
                    window.pageRootLine!.indexOf(+currentItemRelatedPageId) > -1
                ) {
                    this.subnav1Items.forEach(item => item.classList.remove(this.options.mbMainmenuSubnav1CurrentClass));
                    item.classList.add(this.options.mbMainmenuSubnav1CurrentClass);
                }
            });
        }
    }

    private async initEvents() {
        const dropdownToggles = document.querySelectorAll<HTMLElement>(this.options.mbDesktopDropdownToggleSelector);
        const forwardToggles = document.querySelectorAll<HTMLElement>(this.options.mbDesktopForwardToggleSelector);

        // init desktop-navigation dropdown-toggle
        dropdownToggles.forEach(el => {
            el.addEventListener('mouseover', (event) => {
                if (!hdeApp.isTouchDevice() && !isMobileHeader()) {
                    const target = event.currentTarget as HTMLElement;
                    this.openSubnav1(target);
                    this.setDropdownHeight(false);
                }
            });
        });
        dropdownToggles.forEach(el => {
            el.addEventListener('mouseout', (event) => {
                if (!hdeApp.isTouchDevice() && !isMobileHeader()) {
                    const target = event.currentTarget as HTMLElement;
                    this.closeSubnav1(target as HTMLElement);
                    this.setDropdownHeight(false);
                }
            });
        });

        // init desktop-navigation forward-toggle OR tablet-navigation dropdown-toggle
        forwardToggles.forEach(el => {
            el.addEventListener('click', event => {
                const target = event.currentTarget as HTMLElement;
                if (!hdeApp.isTouchDevice()) {
                    this.forwardUrl(target);
                } else {
                    const subnav1Item = target.closest<HTMLElement>(this.options.mbSubnav1ItemSelector)!;

                    if (!subnav1Item?.classList.contains(this.options.mbSubnavItemActiveClass)) {
                        this.openSubnav1(subnav1Item);
                    } else {
                        this.closeSubnav1(subnav1Item);
                    }
                    this.setDropdownHeight(false);
                }
            });
        });

        // toggle mobile navigation burger icon
        this.mobilemenuToggle?.addEventListener('click', () => {
            this.toggleHeaderMobileOpenCloseAction();
        });

        // toggle mobile navigation "back" on sub navigation
        this.mobilemenuToggleBack?.addEventListener('click', () => {
            this.closeAllSubnav1();
            this.toggleHeaderMobileOpenClass(true, false);
        });

        // toggle mobile navigation "fwd" Level-1 and Level-2
        this.subnavItemsToggleFwd.forEach(el => {
            el.addEventListener('click', (event) => {
                const target = event.currentTarget as HTMLElement;
                this.toggleSubnavItemAction(target);
            });
        });

        window.addEventListener('resize', () => {
            this.resizeHeader();
        });

        const resizeWithTimeout = () => {
            setTimeout(() => {
                this.resizeHeader();
            }, 1000);
        };

        // TODO: after drop of supporting Safari 16.3 we can remove deprecated 'orientationchange' event and leave window.screen.orientation listener
        if (window.screen.orientation) {
            window.screen.orientation.addEventListener('change', () => {
                resizeWithTimeout();
            });
        } else {
            window.addEventListener('orientationchange', () => {
                resizeWithTimeout();
            });
        }

        window.addEventListener(this.openLastSubnav1Event, () => {
            const subnav1ItemToggle = document.getElementById(this.getHistorySubnav1Id() || '');
            if (subnav1ItemToggle === null) {
                return;
            }

            const targetSubnav1Item = subnav1ItemToggle.closest<HTMLElement>(this.options.mbSubnavItemSelector);

            if (targetSubnav1Item === null) {
                return;
            }

            this.toggleHeaderMobileOpenClass(true, false);
            this.openSubnav1(targetSubnav1Item);
        });

    }

    private resizeHeader() {
        const isMobile = isMobileHeader();

        if (isMobile !== this.isMobileHeaderLast) {
            this.isMobileHeaderLast = isMobile;

            // Remove default transitions before calculating container heights
            this.header.style.transition = 'none';
            this.element.style.transition = 'none';

            this.closeAllSubnav1();
            this.setDropdownHeight(isMobile);
            this.toggleHeaderMobileClass(isMobile, false);
            this.toggleHeaderMobileOpenClass(isMobile, true);

            // Add back default transitions after calculating container heights
            setTimeout(() => {
                this.header.style.transition = this.header.style.transition.replace(/transition:[\s\w]+;/, '');
                this.element.style.transition = this.element.style.transition.replace(/transition:[\s\w]+;/, '');
            }, 500);
        }
    }

    private lockBodyScroll(lockBodyScroll: boolean) {
        lockBodyScroll ? lockBody() : unlockBody();
    }

    private isMobileHeaderOpen() {
        return this.header.classList.contains(this.options.mbHeaderMobileOpenClass);
    }

    private checkHeaderMobileAction() {
        const isMobile = isMobileHeader();
        const isMobileOpen = this.isMobileHeaderOpen();

        this.setDropdownHeight(isMobile);
        this.toggleHeaderMobileClass(isMobile, isMobileOpen);
        this.toggleHeaderMobileOpenClass(isMobile, !isMobileOpen);
    }

    private toggleHeaderMobileClass(isMobile: boolean, isMobileOpen: boolean) {
        this.header.classList.toggle(this.options.mbHeaderMobileClass, isMobile);
        this.lockBodyScroll(isMobile && isMobileOpen);
        setHeaderWrapperHeight();
    }

    private toggleHeaderMobileOpenCloseAction() {
        const isMobile = isMobileHeader();
        const isMobileOpen = this.isMobileHeaderOpen();

        this.toggleHeaderMobileOpenClass(isMobile, isMobileOpen);
    }

    private toggleHeaderMobileOpenClass(isMobile: boolean, isMobileOpen: boolean) {
        this.header.classList.toggle(this.options.mbHeaderMobileOpenClass, isMobile && !isMobileOpen);
        this.lockBodyScroll(isMobile && !isMobileOpen);
        this.setMainnavBarHeight(isMobile, !isMobileOpen);
    }

    private setDropdownHeight(isMobile: boolean) {
        if (!isMobile) {
            const subnav1ItemsOpen = Array.from(this.subnav1Items).filter(item =>
                item.classList.contains(this.options.mbSubnavItemActiveClass),
            );
            const containerSelector = '.dropdown-menu > .container';
            const subnav1ItemOpen = subnav1ItemsOpen.find(item => item.querySelector(containerSelector));
            const dropdownContainer = subnav1ItemOpen?.querySelector(containerSelector);

            // 9px difference (between 43 and 52) because of 8px padding if dropdown is open
            const dropdownMenuHeight = subnav1ItemsOpen.length ? (dropdownContainer?.clientHeight || 0) + 43 : 52;
            this.subnav1Items.forEach(item => {
                const dropdownMenu = item.querySelector<HTMLElement>('.dropdown-menu');
                if (!dropdownMenu) {
                    return;
                }

                dropdownMenu.style.height = !subnav1ItemsOpen.includes(item) ? '0' : `${dropdownMenuHeight}px`;
            });

            const subnav = document.getElementById('subnav');
            if (!subnav) {
                return;
            }

        } else {
            this.subnav1Items.forEach(item => {
                const dropdownMenu = item.querySelector<HTMLElement>('.dropdown-menu');
                if (dropdownMenu) {
                    dropdownMenu.style.height = 'auto';
                }
            });
        }
    }

    private setMainnavBarHeight(isMobile: boolean, isMobileOpen: boolean) {
        if (isMobile) {
            this.element.style.height = isMobileOpen ? `100%` : '0';

            if (/iPad|iPhone/.test(window.navigator.userAgent)) {
                this.header.scrollTop = 0;
            }
        } else {
            this.element.style.height = 'auto';
        }
    }

    private forwardUrl(target: HTMLElement) {
        const fwdToggle = target.dataset.fwdToggle?.trim();

        if (typeof fwdToggle !== 'undefined' && fwdToggle !== '') {
            window.location.href = fwdToggle;
        }
    }

    private openSubnav1(target: HTMLElement) {
        this.subnav1Items.forEach(item => {
            if (item !== target) {
                item.classList.remove(this.options.mbSubnavItemActiveClass);
            } else {
                item.classList.add(this.options.mbSubnavItemActiveClass);
            }
        });
        this.toggleMainmenuSubnav1();
    }

    private closeSubnav1(target: HTMLElement) {
        const dropdownMenuHovered = target.querySelector<HTMLElement>('.dropdown-menu:hover');

        if (!dropdownMenuHovered) {
            target.classList.remove(this.options.mbSubnavItemActiveClass);
        }
    }

    private closeAllSubnav1() {
        this.subnav1Items.forEach(item => item.classList.remove(this.options.mbSubnavItemActiveClass));
        this.toggleMainmenuSubnav1();
    }

    private toggleMainmenuSubnav1() {
        const isMobile = isMobileHeader();
        const subnav1ItemsOpenLength = this.subnav1Items.filter(item =>
            item.classList.contains(this.options.mbSubnavItemActiveClass)).length;
        this.mainmenu?.classList.toggle(this.options.mbMainmenuSubnav1ActiveClass, isMobile && subnav1ItemsOpenLength > 0);
        this.header.classList.toggle(this.options.mbHeaderMobileSubnavOpenClass, isMobile && subnav1ItemsOpenLength > 0);
        this.setMainnavBarHeight(isMobile, true);
    }

    private toggleSubnavItemAction(curr: HTMLElement) {
        const currentRel = curr.dataset['rel'];
        const targetSubnavItem = curr.closest<HTMLElement>(this.options.mbSubnavItemSelector);
        const currCase = targetSubnavItem?.dataset['rel'] + '-' + currentRel;

        if (currCase === 'subnav1-fwd') {
            this.updateHistorySubnav1Id(curr.closest(this.options.mbSubnavItemToggleParentSelector)!.id);
            this.toggleHeaderMobileOpenClass(true, false);
            this.openSubnav1(targetSubnavItem!);
        }
    }
}

async function initResponsiveMainNavBar() {
    const mainNavBar = document.querySelector<HTMLElement>('header.header > .mainnav-bar');
    if (mainNavBar === null) {
        return;
    }

    new ResponsiveMainNavBar(mainNavBar);

    const searchBar = document.querySelector('header.header > .search-bar');

    if (!searchBar) {
        return;
    }

    getConsentByVendorId(ConsentManagerVendors.GOOGLE_GENERAL).then(() => {
        setGoogleSearch(searchBar);
    });
}

executeOnWindowLoad(initResponsiveMainNavBar);
