import { executeOnWindowLoad } from '@/utils/withWindowLoad';

function initSpecialWindowsForms() {
    const specialWindowForms = document.querySelectorAll('.special-window-forms:not(.collapseMode)');

    specialWindowForms.forEach(specialWindowForm => {
        const specialWindowFormsButton = specialWindowForm.querySelector('.btn');

        if (!specialWindowFormsButton) {
            return;
        }

        let specialWindowFormsButtonHref = specialWindowFormsButton.getAttribute('data-href');

        if (!specialWindowFormsButtonHref) {
            specialWindowFormsButtonHref = specialWindowFormsButton.getAttribute('data-fwd')!;
            specialWindowFormsButton.setAttribute('data-href', specialWindowFormsButtonHref);
        }

        const specialWindowFormsButtonText = specialWindowFormsButton.querySelector('span');
        const specialWindowFormElements = specialWindowForm.querySelectorAll('.special-window-form');

        specialWindowFormElements.forEach(specialWindowFormElement => {
            specialWindowFormElement.addEventListener('click', handleClick);
            specialWindowFormElement.addEventListener('touch', handleClick);

            function handleClick() {
                const specialWindowFormActive = specialWindowForm.querySelectorAll('.active');
                const specialWindowFormName = specialWindowFormElement.querySelector('span')?.textContent;
                const specialWindowFormDataName = specialWindowFormElement.getAttribute('data-name');

                specialWindowFormActive.forEach(form => form.classList.remove('active'));
                specialWindowFormElement.classList.add('active');
                specialWindowFormsButtonText!.innerHTML = specialWindowFormName || '';

                specialWindowFormsButton!.setAttribute('data-fwd', specialWindowFormsButtonHref + specialWindowFormDataName);
            }
        });
    });

    const specialWindowFormsCollapsible = document.querySelectorAll('.special-window-forms.collapseMode');
    if (specialWindowFormsCollapsible.length) {
        specialWindowFormsCollapsible.forEach(function(element,index) {
            const windowTilesCollapse = element.querySelector(".collapse");
            const windowTileBtn = element.querySelector(".text-btn");

            if (!windowTileBtn || !windowTilesCollapse) {
                return;
            }

            const windowTilesCollapseId = windowTilesCollapse.getAttribute("id");
            const windowTileBtnDataTarget = windowTileBtn.getAttribute("data-target");
            const showLess = windowTileBtn.getAttribute("data-less");
            const showMore = windowTileBtn.getAttribute("data-more");

            windowTilesCollapse.setAttribute("id", windowTilesCollapseId + "-" + index);
            windowTileBtn.setAttribute("data-target", windowTileBtnDataTarget + "-" + index);

            windowTileBtn.addEventListener('click', (event) => {
                const btn = event.currentTarget as HTMLElement;
                setTimeout(() => {
                    const isExpanded = btn.getAttribute('aria-expanded');

                    if (isExpanded === 'true') {
                        windowTileBtn.textContent = showLess;
                    } else {
                        windowTileBtn.textContent = showMore;
                    }
                }, 50);
            });
        });
    }
}

executeOnWindowLoad(initSpecialWindowsForms);
