/**
 * Video function creates a YouTube video from a preview image
 *
 * TODO: `frameborder` attribute is deprecated. Use CSS `border: 0` instead.
 */
function showVideo(vid, cid) {
    const el = document.getElementById(cid);
    if (el) {
        el.innerHTML = '<iframe frameborder="0" src="https://www.youtube.com/embed/' +
            vid + '?autoplay=1&rel=0&wmode=transparent" allowfullscreen></iframe>';
    }
}
window.showVideo = showVideo;
