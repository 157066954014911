import { executeOnWindowLoad } from '@/utils/withWindowLoad';

class ProfileList {
    private element: HTMLElement;

    private options: { contentCardSelector: string };

    private contentCards: HTMLElement[] = [];

    private profileCards: HTMLElement[] = [];

    constructor(element: HTMLElement, options = { contentCardSelector: '.content-card' }) {
        const defaults = { contentCardSelector: '.content-card' };
        this.element = element;
        this.options = { ...defaults, ...options };

        this.init();
    }

    private init() {
        this.getUiElements();
        this.handleContentCards();
    }

    private getUiElements() {
        this.contentCards = Array.from(this.element.querySelectorAll(this.options.contentCardSelector));
        this.profileCards = Array.from(this.element.children).filter(el => !this.contentCards.includes(el as HTMLElement)) as HTMLElement[];
    }

    private handleContentCards() {
        if (this.contentCards.length > 0 && this.profileCards.length > 0) {
            let cardDisplay;
            let visibleCards;
            let cardPosition;

            this.contentCards.forEach(card => {
                if (!cardDisplay) {
                    cardDisplay = card.style.display;
                }
                card.style.display = 'none';
            });

            const lastProfileCard = this.profileCards[this.profileCards.length - 1];
            this.contentCards.forEach(card => {
                lastProfileCard.insertAdjacentElement('afterend', card);
            });

            this.contentCards.forEach(card => {
                cardPosition = Number(card.dataset['position']);
                visibleCards = Array.from(this.element.children).filter((element) => getComputedStyle(element).display !== 'none');

                if (cardPosition && cardPosition > 0 && cardPosition <= visibleCards.length + 1) {
                    if (cardPosition <= visibleCards.length) {
                        this.element.insertBefore(card, visibleCards[cardPosition - 1]);
                    }
                    card.style.display = cardDisplay || 'block';
                }
            });
        }
    }
}

executeOnWindowLoad(() => {
    const profilesLists = document.querySelectorAll('.grid-items2 .profiles-list');

    Array.from(profilesLists).forEach(element => {
        const el = element as HTMLElement;
        if (!el.hasAttribute('data-redesign-profile-list')) {
            new ProfileList(el);
            el.setAttribute('data-redesign-profile-list', '');
        }
    });
});
