/**
 * Add handler to open ekko-lightbox (bootstrap modal with transparent modal container
 *
 * TODO: Replace ekko-lightbox solution (Swiper: https://swiperjs.com/)
 */
import initYoutubeConsent from '@/lib/youtube/youtube-consent';

$(document).on(
    'click',
    '*[data-toggle="ekkoLightbox"]',
    function(event) {
        event.preventDefault();
        $(this).ekkoLightbox({
            onContentLoaded: function() {
                const iframes = this._$modal.find('iframe').toArray();
                initYoutubeConsent(iframes);
            },
            onShow: function() {
                $('html').addClass('lightbox-overflow-hidden');
            },
            onHide: function() {
                $('html').removeClass('lightbox-overflow-hidden');
            },
        });
    },
);
