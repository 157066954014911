import { scrollElemToTop } from '@/utils/dom';

export default class TopCategories {
    categoryTeasers: HTMLElement[] = [];
    constructor(categoryTeasers: HTMLElement[]) {
        this.categoryTeasers = categoryTeasers;
        this.initEvents();
    }

    private initEvents() {
        this.categoryTeasers.forEach(category => {
            category.addEventListener('click', this.handleCategoryClick);
        });
    }

    private handleCategoryClick(e: Event) {
        const el = e.currentTarget as HTMLElement;
        const dataRel = el.dataset.rel;
        const currentFilter = document.querySelector<HTMLElement>('.top-category-container .category-teaser[data-rel=' + dataRel + ']');
        const parentContainer = currentFilter?.parentElement as HTMLElement;
        const parentContainerContent = parentContainer?.querySelector<HTMLElement>(`#${dataRel}`);
        if (parentContainerContent) {
            const parentContainerCategories = parentContainer?.querySelectorAll<HTMLElement>('.category-teaser, .category-content');

            parentContainerCategories?.forEach(element => {
                element.style.display = 'none';
            });

            parentContainerContent.style.display = 'block';
        }

        //update breadcrumb
        const breadcrumb = document.querySelector('.breadcrumb') as HTMLElement;
        const breadcrumbTopCategory = document?.querySelector<HTMLElement>('.breadcrumb .top-category');
        const heading = currentFilter?.querySelector<HTMLElement>('.heading')?.textContent || '';
        if (!breadcrumbTopCategory) {
            const span = document.createElement('span');
            span.classList.add('top-category');
            span.innerHTML = `<span class="hidden-xs next-icon"></span><a>${heading}</a>`;
            breadcrumb.appendChild(span);
        } else {
            const anchor = breadcrumbTopCategory.querySelector('a') as HTMLElement;
            anchor.textContent = heading;
        }

        const h1 = document.querySelector<HTMLElement>('.content h1');
        if (h1) {
            h1.setAttribute('data-original-text', h1.textContent!);
            h1.textContent = heading;
        }

        scrollElemToTop(document.body);

        window.history.pushState({}, '');
        window.addEventListener('popstate', () => {
            parentContainer.querySelectorAll<HTMLElement>('.category-teaser').forEach(element => {
                element.style.display = 'block';
            });
            parentContainer.querySelectorAll<HTMLElement>('.category-content').forEach(element => {
                element.style.display = 'none';
            });
            if (h1) {
                h1.innerText = h1.dataset.originalText!;
            }
            document.querySelector('.breadcrumb .top-category')?.remove();
        }, { once: true });
    }
}

