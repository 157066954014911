import { isMobileFooter }      from '@/utils/dom';
import { executeOnWindowLoad } from '@/utils/withWindowLoad';


interface ResponsiveFooterOptions {
    footerMobileClass: string;
}

class ResponsiveFooter {
    private element: HTMLElement;

    private options: ResponsiveFooterOptions;

    private isMobileFooterLast: boolean;

    constructor(element: HTMLElement, options?: ResponsiveFooterOptions) {
        this.element = element;
        this.options = { footerMobileClass: 'footer-mobile', ...options };
        this.isMobileFooterLast = isMobileFooter();
        this.init();
    }

    private init(): void {
        this.initEvents();
        this.toggleFooterMobile(this.isMobileFooterLast);
    }

    private initEvents(): void {
        window.addEventListener('resize', () => this.resizeFooter());

        // TODO: after drop of supporting Safari 16.3 we can remove deprecated 'orientationchange' event and leave window.screen.orientation listener
        if (window.screen.orientation) {
            window.screen.orientation.addEventListener('change', () => this.handleOrientationChange());
        } else {
            window.addEventListener('orientationchange', () => this.handleOrientationChange());
        }
    }

    private resizeFooter(): void {
        const isMobile = isMobileFooter();
        if (isMobile !== this.isMobileFooterLast) {
            this.isMobileFooterLast = isMobile;
            this.toggleFooterMobile(isMobile);
        }
    }

    private handleOrientationChange(): void {
        setTimeout(() => {
            this.resizeFooter();
        }, 50);
    }

    private toggleFooterMobile(isMobile?: boolean): void {
        this.element.classList.toggle(this.options.footerMobileClass, isMobile);
    }
}

executeOnWindowLoad(() => {
    const footer = document.querySelector('body > footer.footer');
    if (!footer) {
        return;
    }
    if (!footer.hasAttribute('data-responsive-footer')) {
        new ResponsiveFooter(footer as HTMLElement);
        footer.setAttribute('data-responsive-footer', '');
    }
});
