import axios from 'axios';

import { getParents, getSiblings, hide, insertAfter, scrollElemToTop, show } from '@/utils/dom';

function initRalColor() {
    if (window.colorDetailLoaderText === undefined || window.ralColorEndpoint === undefined) {
        return;
    }

    const showColorDetailBtnSelector = 'span.showRalColor',
          hideColorDetailBtnSelector = 'span.hideDetail',
          colorListClass             = 'colorList',
          colorDetailSelector        = '.colorDetail';

    const pageContentEl = document.getElementById('content-container');
    const breadcrumbContainerEl = pageContentEl.querySelector('nav.breadcrumb').parentElement;
    const breadcrumbSiblingEls = getSiblings(breadcrumbContainerEl);
    const sortingFilterEls = document.querySelectorAll('.sorting-filters');

    let colorEls: HTMLElement | HTMLElement[] = [];

    if (breadcrumbSiblingEls.some(el => el.classList.contains('widecontent'))) {
        // Full-width pages
        colorEls.push(...breadcrumbSiblingEls.filter(el => el.classList.contains('widecontent')));
        colorEls.forEach(el => {
            el.classList.add(colorListClass);
        });
    } else {
        // Other page templates
        colorEls = document.createElement('div');
        colorEls.classList.add(colorListClass);
        insertAfter(breadcrumbContainerEl, colorEls);
        colorEls.append(...breadcrumbSiblingEls);
    }

    const colorLoaderImage = document.createElement('div');
    colorLoaderImage.id = 'colorLoader';
    colorLoaderImage.style.textAlign = 'center';
    colorLoaderImage.style.marginTop = '20%';
    colorLoaderImage.innerHTML = `<img src="/fileadmin/images/loader.gif" alt="Loader"><br>${window.colorDetailLoaderText}`;
    insertAfter(breadcrumbContainerEl, colorLoaderImage);
    hide(colorLoaderImage);

    const sortItemEl = document.querySelector('.sort-item');
    if (sortingFilterEls.length > 0 && sortItemEl) {
        sortingFilterEls.forEach(el => {
            sortItemEl.parentElement.insertBefore(el, sortItemEl);
        });
        sortingFilterEls.forEach(el => insertAfter(el, document.createElement('br')));
        show(sortingFilterEls);
        sortColor();
    }

    window.addEventListener('popstate', (event: PopStateEvent) => {
        if (typeof event.state === "object" && event.state !== null && event.state.hasOwnProperty('colorId')) {
            showColorDetailByColorId(event.state.colorId);
        } else {
            hideAllColorDetails();
        }
    });

    document.addEventListener('click', event => {
        const target = event.target;
        if (!(target instanceof HTMLElement)) {
            return;
        }

        const showSelector = showColorDetailBtnSelector;
        const showMatch = target.matches(showSelector) ? target : getParents(target, showSelector).pop();
        if (!!showMatch) {
            showColorDetailByColorId(showMatch.getAttribute('rel-id'));
        }

        const hideSelector = colorDetailSelector + ' ' + hideColorDetailBtnSelector;
        const hideMatch = target.matches(hideSelector) ? target : getParents(target, hideSelector).pop();
        if (!!hideMatch) {
            hideAllColorDetails();
        }
    });

    const showColorDetailByColorId = (colorId) => {
        hide(colorEls);
        hide(getSiblings(colorEls, colorDetailSelector));
        show(colorLoaderImage);
        scrollElemToTop(document.documentElement);

        const colorDetailEl = document.getElementById('colorDetail-' + colorId);
        if (colorDetailEl) {
            show(colorDetailEl);
            hide(colorLoaderImage);
        } else {
            axios.get(window.ralColorEndpoint + colorId)
                .then(response => {
                    const template = document.createElement('template');
                    template.innerHTML = response.data;

                    const colorDetail = template.content.firstChild;

                    const el = Array.isArray(colorEls) ? colorEls[0] : colorEls;

                    el.parentElement.insertBefore(colorDetail, el);
                    hide(colorLoaderImage);

                    history.pushState({ colorId: colorId }, colorDetail.querySelector('h1').textContent, location.pathname);
                });
        }
    };

    const hideAllColorDetails = () => {
        show(colorEls);
        hide(getSiblings(colorEls, colorDetailSelector));
        scrollElemToTop(document.documentElement, sortingFilterEls.length ? (sortingFilterEls[0].getBoundingClientRect().top - 100) : 0);
        hide(colorLoaderImage);
    };
}

if (document.readyState === 'complete') {
    initRalColor();
} else {
    window.addEventListener('load', initRalColor);
}

function displayItems(filter: string, items: NodeListOf<HTMLElement>): void {
    items.forEach(item => {
        item.style.display = item.classList.contains(filter) || filter === '*' ? 'block' : 'none';
    });
}

function handleClickEvent(filter: HTMLElement, activeFilter: HTMLElement, items: NodeListOf<HTMLElement>) {
    filter.addEventListener('click', (e) => {
        e.preventDefault();
        activeFilter.classList.remove('active');
        filter.classList.add('active');
        displayItems(filter.dataset.filter || '*', items);
    });
}

function sortColor() {
    const colorLists = document.querySelectorAll<HTMLElement>('.colorList');
    colorLists.forEach(list => {
        const filters = list.querySelectorAll<HTMLElement>('.sorting-filters > a');
        const sortingItems = list.querySelectorAll<HTMLElement>('.sort-item');
        let activeFilter: HTMLElement;
        filters.forEach(filter => {
            if (filter.classList.contains('active')) {
                activeFilter = filter;
            }
        });
        displayItems(activeFilter?.dataset.filter || '*', sortingItems);
        filters.forEach(filter => handleClickEvent(filter, activeFilter!, sortingItems));
    });
}
