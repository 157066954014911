import { createApp, defineComponent } from 'vue';

import Popover                        from '@/vue/components/UI/Popover.vue';

/**
 * Load tooltips for level 3 product pages (volume discounts ─ mengenrabatt)
 *
 * NOTE: Currently not used, but might be used again later
 */
const tooltips = document.querySelectorAll<HTMLSpanElement>('p.show-discount-list span[data-toggle="tooltip"]');
tooltips.forEach(tooltip => {
    const content = tooltip.dataset.title;
    const title = tooltip.textContent;
    const supportsTouch = 'ontouchstart' in window;
    const trigger = supportsTouch ? 'click' : 'hover';

    const vuePopover = defineComponent({
        components: { Popover },
        props:      {
            title:   String,
            content: String,
            trigger: String,
        },
        template:   `<Popover :hover="trigger === 'hover'" placement="top">
            <template #content>
                <div v-html="content" />
            </template>
            <div v-html="title" />
        </Popover>`,
    });

    createApp(vuePopover, { content, title, trigger }).mount(tooltip);
});
