import { getParents } from '@/utils/dom';

/**
 * Convert images from RTE editor to lightbox galleries
 */
const enlargeImages = document.querySelectorAll<HTMLImageElement>('.img-enlarge');
enlargeImages.forEach(image => {
    const parents = getParents(image, 'a') as HTMLAnchorElement[];

    let newEl: HTMLAnchorElement;

    if (parents.length === 0) {
        const aTag = document.createElement('a');
        aTag.setAttribute('href', image.getAttribute('src')!);
        aTag.setAttribute('title', image.getAttribute('title') || '');
        aTag.append(image.cloneNode());

        newEl = aTag;
        image.replaceWith(aTag);
    } else {
        newEl = parents[0];
    }

    newEl.dataset.gallery = 'data-gallery';
    newEl.classList.add('zoomLink');

    const span = document.createElement('span');
    span.classList.add('fa', 'fa-search', 'zoomIcon');
    newEl.prepend(span);
});

/**
 * Wrap images from RTE editor in <figure> and add <figcaption>
 */
const captionImages = document.querySelectorAll<HTMLImageElement>('.img-with-caption');
captionImages.forEach(image => {
    const parents = getParents(image, 'figure');
    let figureElement: HTMLElement;

    if (parents.length === 0) {
        const parentAnchor = getParents(image, 'a')[0] as HTMLAnchorElement;
        figureElement = document.createElement('figure');
        figureElement.append(parentAnchor.cloneNode(true));
        parentAnchor.replaceWith(figureElement);
    } else {
        figureElement = parents[0];
    }

    const imgElement = figureElement.querySelector('img')!;
    const title = imgElement.getAttribute('title') || null;

    if (title) {
        imgElement.setAttribute('title', title.replace(/\\n/g, ' '));
        let captionElement = figureElement.querySelector('figcaption');
        if (!captionElement) {
            captionElement = document.createElement('figcaption');
            captionElement.innerHTML = title.replace(/\\n/g, '<br>');
            figureElement.append(captionElement);
        }

        const parentAnchors = getParents(imgElement, 'a') as HTMLAnchorElement[];
        const parentAnchor = parentAnchors.length > 0 ? parentAnchors[0] : null;
        if (parentAnchor && !parentAnchor.getAttribute('title')) {
            parentAnchor.setAttribute('title', title.replace(/\\n/g, ' ') + ' TEST');
        }
    }
});
