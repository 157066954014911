import './styles.scss';

import { ConsentManagerVendors, getConsentByVendorId, watchConsentChange } from '@/utils/consent-manager';
import { captureError }                                                    from '@/utils/error-logger';
import { pushSearchNoResult, pushSearchRequest }                           from '@/utils/gtm';
import translate                                                           from '@/utils/i18n';
import Logger, { LogLevel }                                                from '@/utils/logger';

interface SearchBarOptions {
    sbActiveClass: string;
    sbActiveSelector: string;
    sbSearchInputSelector: string;
    googleSearchSelector: string;
}

const PROMOTED_CLASS = 'promoted';
const ORIENTATION_CHANGE_TIMEOUT = 50;

class GoogleSearch {
    private options: SearchBarOptions;
    private searchBarParent: HTMLElement | null;
    private searchInput: HTMLInputElement | null;
    private isConsentGranted = false;
    private isSearchSet = false;

    constructor(options?: Partial<SearchBarOptions>) {
        const defaults = {
            sbActiveClass:            'search-active',
            sbActiveSelector:         '#header',
            sbSearchInputSelector:    'input.gsc-input',
            googleSearchSelector:     '.search_i',
        };

        this.options = { ...defaults, ...options };
        this.searchBarParent = null;
        this.searchInput = null;
        this.init();
    }

    private async init(): Promise<void> {
        this.initElements();
        await this.initSearch();
    }

    private initElements(): void {
        this.searchBarParent = document.querySelector(this.options.sbActiveSelector);
    }

    private addInputListeners(): void {
        this.searchInput = document.querySelector(this.options.sbSearchInputSelector);
        const clearButton = document.querySelector('div.gsc-clear-button');
        const searchBox = document.querySelector('form.gsc-search-box');

        if (!searchBox) {
            return;
        }

        searchBox.addEventListener('click', (e) => {
            if (e.target !== clearButton) {
                searchBox?.classList.add('active');
            }
        });

        document.addEventListener('click', (e) => {
            if (!searchBox.contains(e.target as Node)) {
                searchBox.classList.remove('active');
            }
        });

        clearButton?.addEventListener('click', () => {
            searchBox.classList.remove('active');
            this.searchInput!.value = '';
        });

        const loseFocus = () => this.searchInput?.blur();

        if (window.screen.orientation) {
            window.screen.orientation.addEventListener('change', loseFocus);
        } else {
            window.addEventListener('orientationchange', loseFocus);
        }
    }

    private async initSearch() {
        this.isConsentGranted = await getConsentByVendorId(ConsentManagerVendors.GOOGLE_GENERAL);
        if (this.isConsentGranted) {
            this.setProgrammableSearch();
            this.showSearchbar();
            this.isSearchSet = true;
        }

        watchConsentChange(
            ConsentManagerVendors.GOOGLE_GENERAL,
            (value: boolean) => {
                this.isConsentGranted = value;
                if (value) {
                    if (!this.isSearchSet) {
                        this.setProgrammableSearch();
                    }
                    this.showSearchbar();
                } else {
                    this.hideSearchBar();
                }
            },
        );
    }

    private addPlaceholder() {
        const searchInputsFields = document.querySelectorAll<HTMLInputElement>('input.gsc-input');
        Array.from(searchInputsFields).forEach(input => {
            input.placeholder = translate("search.form.placeholder", "frontend");
        });
    }

    private showSearchbar() {
        this.searchBarParent!.classList.add(this.options.sbActiveClass);
    }

    private hideSearchBar() {
        this.searchBarParent!.classList.remove(this.options.sbActiveClass);
    }

    private showNoResult(query: string) {
        const resultsPageWrapper = document.querySelector('.results-page-wrapper');

        if (!resultsPageWrapper) {
            return;
        }
        // if no results string already exist just update text with a new query
        const noResultsString = document.getElementById("no-results-string");
        if (!noResultsString) {
            const errorMessage = 'no-results-string placeholder is missing';
            Logger.log(LogLevel.DEBUG, errorMessage);
            captureError(new Error(errorMessage), LogLevel.DEBUG);
            return;
        }
        noResultsString.innerHTML = translate('search.results.noresults', 'frontend', { "%query%": query });
        this.toggleNoResults(false);
        resultsPageWrapper.setAttribute('data-no-results', 'no-results');
    }

    private toggleNoResults(force: boolean) {
        const cartProductPreview = document.getElementById('product-preview-wrapper')!;
        const noResultsString = document.getElementById('no-results-string')!;

        cartProductPreview.classList.toggle('hidden', force);
        noResultsString.classList.toggle('hidden', force);
    }

    private setProgrammableSearch() {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://cse.google.com/cse.js?cx=${window.googleSearchScriptSrc as string}`;
        document.head.appendChild(script);
        window.__gcse = window.__gcse || {};
        window.__gcse.searchCallbacks = {
            web: {
                ready: (
                    name: string,
                    searchQuery: string,
                    promos: Record<string, string>[],
                    results: Record<string, string>[],
                    resultsDiv: HTMLDivElement,
                ) => {
                    pushSearchRequest(searchQuery);

                    const table = document.createElement('div');
                    const promoTitle = document.createElement('a');
                    let searchResultsCount = 0;
                    if (promos) {
                        const firstPromotedResult = promos[0];
                        const wrapper = this.createWrapperWithAnchor(firstPromotedResult['url'], firstPromotedResult['title'], PROMOTED_CLASS);
                        promoTitle.innerHTML = firstPromotedResult['title'];
                        table.appendChild(wrapper);
                        searchResultsCount = searchResultsCount + 1;
                    }
                    if (results) {
                        for (const result of results) {
                            if (promoTitle.innerHTML === result['title']) {
                                continue;
                            }
                            const wrapper = this.createWrapperWithAnchor(result['url'], result['title']);
                            table.appendChild(wrapper);
                            searchResultsCount = searchResultsCount + 1;
                            if (searchResultsCount >= 5) {
                                break;
                            }
                        }
                    }
                    this.toggleNoResults(true);
                    resultsDiv.appendChild(table);
                    if (searchResultsCount === 0) {
                        pushSearchNoResult(searchQuery);
                        this.showNoResult(searchQuery);
                    }
                    const loadingWrapper = document.getElementById("results-loading-wrapper");
                    if (loadingWrapper) {
                        loadingWrapper.remove();
                    }
                    return true;
                },
            },
        };

        setTimeout(() => {
            this.addPlaceholder();
            this.addInputListeners();
        }, 500);
    }

    private createWrapperWithAnchor(url: string, title: string, promoted?: string) {
        const anchor = document.createElement('a');
        const arrow = document.createElement('i');
        arrow.classList.add('fa','fa-chevron-right','fa-fw');
        anchor.href = url;
        anchor.target = '_blank';
        anchor.classList.add('gs_title');
        anchor.innerHTML = title;
        const wrapper = document.createElement('div');
        wrapper.classList.add('gsc_result_item');
        if (promoted) {
            wrapper.classList.add(promoted);
        }
        wrapper.appendChild(arrow);
        wrapper.appendChild(anchor);
        return wrapper;
    }
}

export default function setGoogleSearch(element: Element): void {
    const searchInput = document.querySelector<HTMLElement>('.search_i > div');
    const isResultUrlExist =  searchInput && searchInput.dataset.resultsurl;
    if (isResultUrlExist && !element.hasAttribute('data-google-search') && window.googleSearchScriptSrc) {
        setTimeout(() => {
            new GoogleSearch();
            element.setAttribute('data-google-search', '');
        }, ORIENTATION_CHANGE_TIMEOUT);
    }
}
